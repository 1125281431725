import React, { useState } from "react"
import styled from "styled-components"
import { get } from "lodash"

import api from "../../../utils/api"
import debounce from "../../../utils/debounce"
import deserialize from "../../../utils/deserialize"

const Container = styled.div`
  border-top: 1px solid #dadce0;
  margin-top: 40px;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-bottom: 10px;

    *:disabled {
      background: #e0e3e5;
      cursor: progress;
    }

    input {
      border: 1px solid #e0e3e5;
      box-sizing: border-box;
      border-radius: 4px;
      outline: none;

      &:focus {
        border-color: #667af4;
      }
    }

    button {
      font-weight: 700;
      background: #667af4;
      border-radius: 4px;
      color: white;
      border: none;
      font-size: 14px;
    }
  }

  .row > * {
    width: 48%;
  }

  .error {
    color: #ff6363;
  }

  a {
    text-decoration-style: dotted;
    color: gray;
  }
`

const getCodeData = debounce(async code => {
  let error, data
  try {
    const res = await api.get("/promotion_codes/" + code)
    if (res.data) {
      data = await deserialize(res.data)
    } else {
      console.error(res)
    }
  } catch (e) {
    error = get(
      e,
      "response.data.errors[0].detail",
      e.error || e.message || "Unexpected error occured!"
    )
    console.error(e)
  }

  return [error, data]
})

const DiscountCoupon = ({ setPromo, disabled }) => {
  const [code, setCode] = useState("")
  const [error, setError] = useState("")

  const handleChange = e => {
    const code = e.target.value
    setCode(code)
  }

  const checkCode = debounce(async code => {
    if (!code) return
    const [error, data] = await getCodeData(code)

    if (error || !data.active) {
      setError(error || "Promotion code is invalid")
      setPromo(null)
    } else {
      setPromo(data)
    }
  })

  const handleSubmit = e => {
    e.preventDefault()
    setError("")
    checkCode(code)
  }

  const removeCode = e => {
    e.preventDefault()
    setError("")
    setCode("")
    setPromo(null)
  }

  return (
    <Container>
      <h3>Add a promo code</h3>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <input
            value={code}
            onChange={handleChange}
            onBlur={handleChange}
            name="promo-code"
            disabled={disabled}
          />
          <button children="Apply" disabled={disabled} />
        </div>
        {code && (
          <a href="#" role="button" onClick={removeCode}>
            Remove code
          </a>
        )}
      </form>
      {error && <p className="error">{error}</p>}
    </Container>
  )
}

export default DiscountCoupon
