import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { parse } from "query-string"

import { Subscribe } from "unstated"
import RPContainer from "../../store"
import ProductStore from "../../stores/products"
import RockPointStore from "../../stores/rockpoints"

import withLayout from "../../components/layout"
import {
  MyPurchaseProxyButton,
  CoinbaseDisclaimer,
} from "../../components/CheckoutHelpers"
import CheckoutPriceCard from "../../components/CheckoutPriceCard"
import CoinbaseWaitingModal from "../../components/CoinbaseWaitingModal"

import coinbaseLogo from "../../imgs/coinbase.png"
import poweredByStripe from "../../imgs/powered-by-stripe.svg"
import cardsMini from "../../imgs/cards-mini.svg"
import loadingSpinner from "../../imgs/loading-spinner.svg"

import DiscountCoupon from "./components/_DiscountCoupon"
import api from "../../utils/api"
import debounce from "../../utils/debounce"
const DesktopContainer = styled.div`
  @media screen and (min-width: 961px) {
    height: 100vh;
    width: 100vw;
    left: 0;
    position: absolute;

    &:after {
      content: "";
      background: #eefaf4;
      position: absolute;
      left: calc((100vw - 930px) / 2 + 372px);
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .child1 {
    max-width: 930px;
    margin: 0 auto;
    display: flex;
    flex-flow: row;
    padding: 0 20px;
  }

  .c1-flex {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    padding-right: 4%;
  }

  h3 {
    color: #9ba1aa;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  .button-container button {
    background: white;
    border: 1px solid #c9c9ca;
    width: 47%;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 4px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    cursor: pointer;
    transition: all 0.35s;
    line-height: 1.5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04),
      0px 5px 10px rgba(0, 0, 0, 0.04);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    @media screen and (max-width: 960px) {
      width: 100%;
      height: 100px;
    }

    &:disabled {
      transform: scale(0.95);
      background: #f0f0f0;
      cursor: progress;
    }
  }

  .button-container button.active {
    border: 2px solid #667af4;
    outline: none;
  }

  img {
    max-width: 100%;

    @media screen and (max-width: 960px) {
      max-height: 100%;
    }
  }

  .stripe {
    margin-top: 4px;
    margin-bottom: 8px;

    @media screen and (min-width: 961px) {
      max-width: 60%;
    }
  }

  .gray {
    color: #9ba1aa;
  }

  .small {
    font-size: 12px;
    line-height: 1.2;
  }
`

const MobileContainer = styled(DesktopContainer)`
  display: flex;
  flex-flow: column;
`
const TopDiv = styled.div`
  padding: 0 30px;
`

const GreenContainer = styled.div`
  margin-top: 20px;
  background: #eefaf4;
`

export const CHECKOUT_STATUS = {
  INITIAL: "INITIAL",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
}

const getTitle = status => {
  switch (status) {
    case CHECKOUT_STATUS.SUCCESS:
      return "Success!"

    default:
      return "Checkout"
  }
}

const getButtonTitle = status => {
  switch (status) {
    case CHECKOUT_STATUS.SUCCESS:
      return "See my proxy!"

    default:
      return "Buy now"
  }
}

const METHODS = {
  STRIPE: "STRIPE",
  COINBASE: "COINBASE",
}

function AddProxyPage2({
  status = CHECKOUT_STATUS.INITIAL,
  location,
  isMobile,
  authStore,
  navigate,
}) {
  const [method, setMethod] = useState(METHODS.STRIPE)
  const [promo, setPromo] = useState(null)
  const [purchasing, setPurchasing] = useState(false)

  const searchParams = parse(location.search)
  const { selectedTier2Id, selectedTier3Id, locationID } = searchParams
  const mountedRef = useRef(true)
  const [invalidationCalled, setInvalidationCalled] = useState(false)
  const invalidatePendingSubscription = debounce(async () => {
    try {
      await api.get(
        `/subscriptions/invalidate?timestamp=${new Date().getTime()}`
      )

      if (mountedRef.current) {
        setInvalidationCalled(true)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Couldnot invalidate subscriptions")
      setInvalidationCalled(true)
    }
  })
  useEffect(() => {
    invalidatePendingSubscription()
    return () => {
      invalidatePendingSubscription.cancel()
      mountedRef.current = false
    }
  }, [])
  if (!authStore.isAuthenticated) return null
  if (!invalidationCalled) {
    return (
      <div style={{ textAlign: "center" }}>
        <img src={loadingSpinner} alt="spinner" />
      </div>
    )
  }
  return (
    <Subscribe to={[RPContainer, ProductStore, RockPointStore]}>
      {(rpstore, productStore, pointsStore) => {
        const handleSuccess = () => {
          rpstore.fetchSubscriptions()
          navigate("/success" + location.search)
        }

        if (!productStore.state.locationID) {
          productStore.setProductTiers({
            locationID,
            selectedTier2Id,
            selectedTier3Id,
          })
        }

        const selectedType = productStore.proxyTypes.find(
          elem => elem.id === selectedTier2Id
        )
        if (!selectedType) return null
        const typePrice = selectedType.price ? selectedType.price : 0
        if (productStore.ipRotations.length === 0) return null
        const selectedRota = productStore.ipRotations.find(
          elem => elem.id === selectedTier3Id
        )
        let subTotal = typePrice + selectedRota.price
        let discount = 0
        let credit = pointsStore.nextBillDiscount

        if (promo) {
          const { amountOff, percentOff } = promo.coupon
          discount = amountOff
            ? subTotal - amountOff
            : (percentOff * subTotal) / 100
          subTotal -= discount
        }

        if (credit > 0) {
          if (subTotal > credit) {
            subTotal -= credit
          } else {
            credit = subTotal
            subTotal = 0
          }
        }

        const handleClick = e => {
          if (e) {
            e.preventDefault()
          }

          if (status === CHECKOUT_STATUS.SUCCESS) {
            pointsStore.fetchData()
            return navigate("/proxies", { replace: true })
          }
        }

        return (
          <React.Fragment>
            {!isMobile && (
              <DesktopContainer>
                <div className="child1">
                  <div className="c1-flex">
                    <h1>{getTitle(status)}</h1>

                    <div style={{ width: "100%" }}>
                      <h3>Choose a payment method</h3>
                      <div className="button-container">
                        <button
                          className={method === METHODS.STRIPE ? "active" : ""}
                          onClick={() => setMethod(METHODS.STRIPE)}
                          disabled={purchasing}
                        >
                          <img
                            src={poweredByStripe}
                            alt="Stripe"
                            className="stripe"
                          />
                          <img src={cardsMini} alt="Credit Card" />
                        </button>
                        <button
                          className={
                            method === METHODS.COINBASE ? "active" : ""
                          }
                          disabled={purchasing}
                          onClick={() => setMethod(METHODS.COINBASE)}
                        >
                          <img src={coinbaseLogo} alt="coinbase" />
                        </button>
                      </div>

                      <DiscountCoupon
                        setPromo={setPromo}
                        disabled={purchasing}
                      />
                    </div>
                    {method === METHODS.COINBASE && <CoinbaseDisclaimer />}
                    <MyPurchaseProxyButton
                      selectedFeatures={[selectedTier2Id, selectedTier3Id]}
                      selectedCardId={rpstore.state.selectedCardId}
                      disabled={status !== CHECKOUT_STATUS.INITIAL}
                      onSuccess={handleSuccess}
                      onClick={handleClick}
                      id={
                        status === CHECKOUT_STATUS.INITIAL ? "buynow_btn" : ""
                      }
                      stripeEnabled={method === METHODS.STRIPE}
                      subTotal={subTotal}
                      promo={promo}
                      purchasing={purchasing}
                      setPurchasing={setPurchasing}
                      locationID={locationID}
                      pointsStore={pointsStore}
                    >
                      {getButtonTitle(status)}
                    </MyPurchaseProxyButton>
                    {method === METHODS.COINBASE && (
                      <p className="gray small">
                        *your credit must be equal or exceed the total order
                        amount to be able to make a payment
                      </p>
                    )}
                  </div>
                  <CheckoutPriceCard
                    {...{
                      subTotal,
                      discount,
                      credit,
                      selectedRota,
                      selectedType,
                      promo,
                      isMobile,
                      pointsStore,
                    }}
                  />
                </div>
              </DesktopContainer>
            )}
            {isMobile && (
              <MobileContainer>
                <TopDiv>
                  <h1 style={{ textAlign: "center" }}>{getTitle(status)}</h1>
                  <h3>Choose a payment method</h3>
                  <div className="button-container">
                    <button
                      className={method === METHODS.STRIPE ? "active" : ""}
                      onClick={() => setMethod(METHODS.STRIPE)}
                      disabled={purchasing}
                    >
                      <img
                        src={poweredByStripe}
                        alt="Stripe"
                        className="stripe"
                      />
                      <img src={cardsMini} alt="Credit Card" />
                    </button>
                    <button
                      className={method === METHODS.COINBASE ? "active" : ""}
                      onClick={() => setMethod(METHODS.COINBASE)}
                      disabled={purchasing}
                    >
                      <img src={coinbaseLogo} alt="coinbase" />
                    </button>
                  </div>
                  <DiscountCoupon setPromo={setPromo} disabled={purchasing} />
                </TopDiv>
                <GreenContainer>
                  <CheckoutPriceCard
                    {...{
                      subTotal,
                      discount,
                      credit,
                      selectedRota,
                      selectedType,
                      promo,
                      isMobile,
                      pointsStore,
                    }}
                  />
                </GreenContainer>
                {method === METHODS.COINBASE && <CoinbaseDisclaimer />}
                <MyPurchaseProxyButton
                  selectedFeatures={[selectedTier2Id, selectedTier3Id]}
                  selectedCardId={rpstore.state.selectedCardId}
                  disabled={status !== CHECKOUT_STATUS.INITIAL}
                  onSuccess={handleSuccess}
                  onClick={handleClick}
                  id={status === CHECKOUT_STATUS.INITIAL ? "buynow_btn" : ""}
                  stripeEnabled={method === METHODS.STRIPE}
                  subTotal={subTotal}
                  promo={promo}
                  purchasing={purchasing}
                  setPurchasing={setPurchasing}
                  locationID={locationID}
                  pointsStore={pointsStore}
                >
                  {getButtonTitle(status)}
                </MyPurchaseProxyButton>
                {method === METHODS.COINBASE && (
                  <p className="gray small">
                    *your credit must be equal or exceed the total order amount
                    to be able to make a payment
                  </p>
                )}
              </MobileContainer>
            )}

            <CoinbaseWaitingModal
              isOpen={purchasing && method === METHODS.COINBASE}
            />
          </React.Fragment>
        )
      }}
    </Subscribe>
  )
}

export default withLayout(AddProxyPage2)
